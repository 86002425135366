<template>
  <span v-if="parserTag.tagNames && parserTag.tagSetup">
    Łącznie tagów XML: {{ parserTag.tagNames.length }}
    <br />
    Łączna ilość tagów niedopasowanych: {{  requiredTagsCount() }}
    <br />
    Łączna ilość tagów odrzuconych: {{ rejectedTags() }}
  </span>
</template>

<script>
export default {
  props: {
    parserTag: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      requiredTags: [
        "product",
        "warehouseProductId",
        "name",
        "description",
        "price"
      ],
    }
  },

  methods: {
    rejectedTags() {
      const rejectedTags = this.parserTag.tagSetup.rejectedTags
      if (!rejectedTags) {
        return 0
      }

      return rejectedTags.length || 0
    },

    requiredTagsCount() {
      const matchedRequiredTags = this.parserTag.tagSetup.matchedTags ? this.parserTag.tagSetup.matchedTags.filter(matchedTag => this.requiredTags.includes(matchedTag.name)) : [];
      const missingRequiredCount = this.requiredTags.length - matchedRequiredTags.length;

      return Math.max(missingRequiredCount, 0);
    }
  }
}
</script>